import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderDS";
import Footer from "../component/footer/FooterTwo";



class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Система CRM для підприємства' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Планувальник медичних звернень та обстежень</h2>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        
                                        <p className="subtitle">Розробка та впровадження планувальника медичних звернень та обстежень в рамках підприємства для його працівників.</p>
                                        <p>Програмний продукт містить наступні модулі:<br /><br />
                                        <ol>
<li>Створення та керування розкладом: <br />- додавання нових медичних звернень та обстежень до розкладу; <br />- визначення дати та часу звернення; <br />- можливість зміни або видалення існуючих звернень.</li>
<li>Нагадування про заплановане звернення:<br />- надсилання сповіщень користувачеві за певний час до назначеного звернення; <br />- можливість налаштування частоти та способу нагадування (повідомлення в додатку, SMS, електронна пошта тощо).</li>
<li>Історія звернень: <br />- збереження історії медичних звернень та обстежень; <br />- доступ до попередніх звернень для перегляду, внесення змін або аналізу.</li>
<li>Аналітика та звітність: <br />- відображення статистики щодо кількості та типів звернень, часу очікування, ефективності обстежень; <br />- забезпечення можливості створення звітів для аналізу діяльності та планування.</li>
<li>Інтеграція з іншими системами: <br />- можливість інтеграції з системами електронної медичної історії або іншими медичними системами для обміну даними та автоматизації процесів; <br />- можливість інтеграції з системою управління персоналом для автоматичного планування звернень враховуючи робочі графіки та навантаження працівників.</li>
</ol></p>
                                        
                                    </div>
                                    <div className="portfolio-thumb-inner">                         
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-01-2.png" alt="Portfolio Images medical"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;

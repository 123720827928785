import React, { Component , Fragment } from "react";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import Header from "../component/header/HeaderDS";
import SliderDigitalServices1 from "../component/slider/SliderDigitalServices1";
import About from "../component/HomeLayout/homeOne/About";
import ContactOne from "../elements/contact/ContactTwoDS";
import FooterTwo from "../component/footer/FooterTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";


const list = [
    {
        image: 'image-1',
        category: 'Розробка ПЗ',
        title: 'Система CRM для підприємства',
        link: 'portfolio-details/crm-system'
    },
    {
        image: 'image-2',
        category: 'Розробка ПЗ',
        title: 'Планувальник медичних звернень та обстежень',
        link: 'portfolio-details/medical'
    },
    {
        image: 'image-3',
        category: 'Розробка ПЗ',
        title: 'AI чат-бот',
        link: 'portfolio-details/chat'
    }
]

class CreativeAgency extends Component{
    render(){
        return(
            <Fragment>
                <Helmet pageTitle="Студія цифрових сервісів" />
                <Header logo="light" />
                {/* Start Slider Area */}
                <div className="slider-wrapper">
                    <SliderDigitalServices1 />
                </div>
                {/* End Slider Area     */}
                
                {/* Start About Area  */}
                <div className="about-area about-position-top pb--120">
                    <About />
                </div>
                {/* End About Area   */}

                {/* Start Service Area   */}
                {/* End Service Area */} 

                {/* Start Portfolio Area  */}
                <div id="portfolio" className="portfolio-area pt--120 pb--140 bg_image bg_image--3">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 className="title">Останні проєкти</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="portfolio" key={index}>
                                                    <div className="thumbnail-inner">
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        <div className={`bg-blr-image ${value.image}`}></div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <p>{value.category}</p>
                                                            <h4><a href={value.link}>{value.title}</a></h4>
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn" href={value.link}>Докладніше</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link className="link-overlay" to={value.link}></Link>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}
               {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactOne />
                </div>
            </div>
            {/* End COntact Area */}

                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default CreativeAgency;
import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Про нас',
        description = 'Ми розуміємо потреби сучасних компаній та прагнемо створювати інноваційні та корисні застосунки, які допомагають нашим клієнтам оптимізувати їхні бізнес-процеси та досягати успіху. Поєднуючи креативність та стратегічне мислення, ми пропонуємо інтегровані рішення, що відповідають вимогам ринку та забезпечують конкурентні переваги наших клієнтів.',
        description1 = 'Наша мета полягає в тому, щоб наші продукти і сервіси не лише відповідали сучасним стандартам, але й виходили за їх межі, відкриваючи нові можливості для розвитку та зростання.',
        description2 = 'Наша команда завжди відкрита до співпраці та постійно вдосконалюється, щоб надавати найкращі рішення, які відповідають найвищим стандартам якості та ефективності.',
        description3 = 'Наші цінності полягають у якості, інноваціях та клієнтоорієнтованості.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about_DS.png" alt="About Images"/>
                                </div>
                            </div>

                            <div id="about" className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description1}</p>
                                        <p className="description">{description3}</p>
                                        <p className="description">{description2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;
import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Робота з проблемами \n бізнесу',
        description: 'Дослідження, аналіз проблем керування процесами та причин їх виникнення. Побудова логічної матриці, стратегування.'
    },
    {
        icon: '02',
        title: 'Моделювання \n бізнес-процесів',
        description: 'Аналіз, опис, моделювання бізнес-процесів. Цифрова трансформація внутрішніх та зовнішніх процесів підприємства.'
    },
    {
        icon: '03',
        title: 'Розробка \n цифрових продуктів',
        description: 'Розробка кросплатформних застосунків, орієнтованих на трансформаційні процеси підприємства.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderDS";
import Footer from "../component/footer/FooterTwo";



class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Система CRM для підприємства' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Система CRM для підприємства</h2>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        
                                        <p className="subtitle">Розробка та впровадження програмного забезпечення для внутрішнього документообігу та організації взаємодії між працівниками та підрозділами підприємства.</p>
                                        <p>Програмний продукт містить наступні модулі:<br /><br />
                                        <ol>
<li>Управління контактами з клієнтами: <br />- облік історії контактів з клієнтами, реєстрація інтересів клієнтів, диспетчеризація контактів клієнтів в режимі реального часу, оперативна передача інформації між відділами, планування контактів, шаблони типових контактів з клієнтами, виділене робоче місце для обробки масових звернень клієнтів; <br />- планування кількості контактів з клієнтами в розрізі менеджерів, підрозділів, план-фактний аналіз контактів.</li>
<li>Управління робочим часом (тайм-менеджмент):<br />- перегляд запланованих контактів (зустрічей, нарад і т.д.) в "Календарі"; <br />- підбір учасників зустрічі з урахуванням їх зайнятості, одночасний перегляд календарів декількох користувачів (або груп), кольорове відображення подій в календарі; <br />- використання графіків роботи користувачів, резервування та планування зайнятості приміщень, ведення "Списку справ" з можливістю резервування з нього часу в календарі; <br />- регулярне планування контактів (нарад, зустрічей тощо).</li>
<li>Управління продажами: <br />- управління стадіями та етапами продажу на основі бізнес-процесів, створення типових шаблонів дій з продажу; <br />- контроль і аналіз простроченої заборгованості; <br />- призначення цін і знижок, механізм автоматичної підготовки комерційних пропозицій та договорів на основі корпоративних шаблонів (в MS Word або OpenOffice); <br />- механізм оперативного управління та аналізу циклу продажів - "воронка продажів", створення різних варіантів перегляду "воронки продажів" і планування щодо їх виконання; <br />- планування продажів в різних розрізах: номенклатурі, клієнтам, проектам, менеджерам і підрозділам, план-фактний аналіз продажів.</li>
<li>Система оповіщень: <br />- можливість отримання сповіщень через: нагадування в рішенні, e-mail, SMS; <br />- індивідуальна настройка сповіщень для планованих / прострочених подій в Календарі, нових / прострочених завданнях (доручення, інші бізнес-процеси); <br />- створення індивідуальних нагадувань (скарги і т. д.).</li>
<li>Загальний електронний обіг документів: <br />- вхідні документи (листи, звернення, запити) - отримання вхідних документів, реєстрація вхідних документів, накладання резолюції на вхідні документи, постановка на контроль, виконання, зберігання; <br />- вихідні документи - створення проектів вихідних документів, реєстрація вихідних документів, відправка вихідних документів, зберігання; <br />- внутрішні документи.</li>
<li>Сервісне і гарантійне обслуговування. Облік товарів, що знаходяться на обслуговуванні:<br />- по серійних номерах, строкам і типам обслуговування, управління сервісними зверненнями клієнтів; <br />- управління зверненнями, сервісними замовленнями.</li>
</ol></p>
                                        
                                    </div>
                                    <div className="portfolio-thumb-inner">                         
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-01.png" alt="Portfolio Images CRM"/>
                                        </div>
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.png" alt="Portfolio Images CRM"/>
                                        </div>
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.png" alt="Portfolio Images CRM"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
